<template>
    <li v-if="items" class="expand-menu">
        <div class="categories-base scroll level-1-div" @scroll="handleArrowVisibility(1, null)">
            <div class="cl-item" v-for="item in items" :key="item.code" @mouseover="changeHoverCategory(1, item.code, item.subitems.length);" @mouseleave="changeHoverCategory(1, null, null)">
                <nuxt-link :to="item.route" class="cate-name d-flex justify-content-between align-items-center">
                    {{ truncate(item.name, 32) }}
                    <div v-if="item.subitems && item.subitems.length" class="arrow-expand">
                        <svg width="18px" heigth="18px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" >
                            <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M6.29289 8.79289C6.68342 8.40237 7.31658 8.40237 7.70711 8.79289L12 13.0858L16.2929 8.79289C16.6834 8.40237 17.3166 8.40237 17.7071 8.79289C18.0976 9.18342 18.0976 9.81658 17.7071 10.2071L12.7071 15.2071C12.3166 15.5976 11.6834 15.5976 11.2929 15.2071L6.29289 10.2071C5.90237 9.81658 5.90237 9.18342 6.29289 8.79289Z" />
                        </svg>
                    </div>
                </nuxt-link>
                <div v-if="item.subitems && item.subitems.length" class="categories-base megamenu-view second-level" :class="{ 'hover-category': item.code === hover_level_1 }">
                    <div class="megamenu-view-list scroll" :class="`level-2-div-${item.code}`" @scroll="handleArrowVisibility(2, item.code)">
                        <div v-for="subItem in item.subitems" :key="subItem.code" class="sub-cate-name" @mouseover="changeHoverCategory(2, subItem.code, subItem.subitems.length)" @mouseleave="changeHoverCategory(2, null, null)">
                            <nuxt-link :to="subItem.route" class="d-flex justify-content-between align-items-center">
                                {{ truncate(subItem.name, 32 )}}
                                <div v-if="subItem.subitems && subItem.subitems.length" class="arrow-expand">
                                    <svg width="18px" heigth="18px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" >
                                        <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M6.29289 8.79289C6.68342 8.40237 7.31658 8.40237 7.70711 8.79289L12 13.0858L16.2929 8.79289C16.6834 8.40237 17.3166 8.40237 17.7071 8.79289C18.0976 9.18342 18.0976 9.81658 17.7071 10.2071L12.7071 15.2071C12.3166 15.5976 11.6834 15.5976 11.2929 15.2071L6.29289 10.2071C5.90237 9.81658 5.90237 9.18342 6.29289 8.79289Z" />
                                    </svg>
                                </div>
                            </nuxt-link>
                            <div v-if="subItem.subitems && subItem.subitems.length">
                                <div class="categories-base megamenu-view third-level" :class="{ 'hover-category': subItem.code === hover_level_2 }">
                                    <div class="megamenu-view-list scroll" :class="`level-3-div-${subItem.code}`" @scroll="handleArrowVisibility(3, subItem.code)">
                                        <div v-for="nestedSubItem in subItem.subitems" :key="nestedSubItem.code" class="nested-cate-name" @mouseover="changeHoverCategory(3, nestedSubItem.code, nestedSubItem.subitems.length)" @mouseleave="changeHoverCategory(3, null, null)">
                                            <nuxt-link :to="nestedSubItem.route" class="d-flex justify-content-between align-items-center">
                                                {{ truncate(nestedSubItem.name, 32) }}
                                                <div v-if="nestedSubItem.subitems && nestedSubItem.subitems.length" class="arrow-expand">
                                                    <svg width="18px" heigth="18px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" >
                                                        <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M6.29289 8.79289C6.68342 8.40237 7.31658 8.40237 7.70711 8.79289L12 13.0858L16.2929 8.79289C16.6834 8.40237 17.3166 8.40237 17.7071 8.79289C18.0976 9.18342 18.0976 9.81658 17.7071 10.2071L12.7071 15.2071C12.3166 15.5976 11.6834 15.5976 11.2929 15.2071L6.29289 10.2071C5.90237 9.81658 5.90237 9.18342 6.29289 8.79289Z" />
                                                    </svg>
                                                </div>
                                            </nuxt-link>
                                            <div v-if="nestedSubItem.subitems && nestedSubItem.subitems.length">
                                                <div class="categories-base megamenu-view fourth-level" :class="{ 'hover-category': nestedSubItem.code === hover_level_3 }">
                                                    <div class="megamenu-view-list scroll" :class="`level-4-div-${nestedSubItem.code}`" @scroll="handleArrowVisibility(4, nestedSubItem.code)">
                                                        <div v-for="innermostSubcategory in nestedSubItem.subitems" :key="innermostSubcategory.code" class="nested-cate-name">
                                                            <nuxt-link :to="innermostSubcategory.route" class="d-flex justify-content-between align-items-center">
                                                                {{ truncate(innermostSubcategory.name, 32) }}
                                                            </nuxt-link>
                                                        </div>
                                                    </div>
                                                    <MegaMenuArrow :level="4" :code="nestedSubItem.code" :refValue="`arrow-${nestedSubItem.code}`" :itemsLength="nestedSubItem.subitems.length" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <MegaMenuArrow :level="3" :code="subItem.code" :refValue="`arrow-${subItem.code}`" :itemsLength="subItem.subitems.length" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <MegaMenuArrow :level="2" :code="item.code" :refValue="`arrow-${item.code}`" :itemsLength="item.subitems.length" />
                </div>
            </div>
        </div>
        <MegaMenuArrow :level="1" :itemsLength="items.length" />
    </li>
</template>
<script>
    import MegaMenuArrow from "~/components/MegaMenu/MegaMenuArrow.vue";
    import utils from "~/mixins/utils";
    export default {
        components: {
            MegaMenuArrow
        },
        mixins: [
            utils
        ],
        props: {
            items: {
                type: Array,
                default: () => []
            },
        },
        data() {
            return {
                hoverCategoryTimeout: null,
                hoverSubCategoryTimeout: null,
                nestedSubcategoryTimeout: null,
                hover_level_1: null,
                hover_level_2: null,
                hover_level_3: null,
            };
        },
        methods: {
            changeHoverCategory(level, code) {
                const timeoutVariables = ['hoverCategoryTimeout', 'hoverSubCategoryTimeout', 'nestedSubcategoryTimeout'];
                const timeoutVar = timeoutVariables[level - 1];
                if (timeoutVar && this[timeoutVar]) {
                    clearTimeout(this[timeoutVar]);
                }
                this[timeoutVar] = setTimeout(() => {
                    this[`hover_level_${level}`] = code;
                }, 350);
            },
            toggleArrowVisibility(level, code) {
                const levelClassName = !code ? `.level-${level}-div` : `.level-${level}-div-${code}`;
                const selectedLevelDiv = this.$el.querySelector(levelClassName);
                const isAtTop = selectedLevelDiv.scrollTop === 0;
                const isAtBottom = selectedLevelDiv.scrollTop === (selectedLevelDiv.scrollHeight - selectedLevelDiv.clientHeight);
                const arrowUp = selectedLevelDiv.parentElement.querySelector(`.level-${level}-arrow-up`);
                const arrowDown = selectedLevelDiv.parentElement.querySelector(`.level-${level}-arrow-down`);
                if (arrowUp) {
                    arrowUp.style.setProperty('display', isAtTop ? 'none' : 'flex', 'important');
                }
                if (arrowDown) {
                    arrowDown.style.setProperty('display', isAtBottom ? 'none' : 'flex', 'important');
                }
            },
            handleArrowVisibility(level, code) {
                this.toggleArrowVisibility(level, code);
            },
        },
    };
</script>
