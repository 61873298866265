import Cookies from 'js-cookie';

export default {
    methods: {
        fbqEvent(track, event, params, counter = 10) {
            setTimeout(() => {
                if (counter <= 0) {
                    return
                }
                counter--;
                try {
                    fbq(track, event, params);
                }
                catch(e) {
                    setTimeout(() => {
                        this.fbqEvent();
                    },500);
                }
            },500);
        },
        LgpdResponse() {
            let lgpd_marketing = Cookies.get('lgpd_load_marketing')
            if (this.$wdconfig.default.google_tag_manager && lgpd_marketing === 'true' ) {
                return true;
            }
            return false;
        },
        FacebookPixelAddToCart(value, id, name,  quantity = 1) {
            if (this.LgpdResponse()) {
                this.fbqEvent('track', 'AddToCart', {
                    currency: "BRL",
                    content_type: "product",
                    value: value,
                    contents: {
                        id: id,
                        quantity: quantity,
                        name: name,
                    }
                })
            }
        },
        FacebookViewContent( category, name, type, id, value) {
            if (this.LgpdResponse()) {
                this.fbqEvent('track', 'ViewContent', { 
                    content_name: name,
                    content_category: category,
                    content_type: type,
                    content_ids: [id],
                    value: value,
                    currency: 'BRL' 
                    });
            }
        },
    }
}
