<template>
    <div v-if="itemsLength && itemsLength >= 16">
        <div v-for="direction in ['up', 'down']" :key="direction + code" class="scroll-arrow d-flex justify-content-center align-items-center p-2" :class="`level-${level}-arrow-${direction} ${direction}`" @mouseenter="scrollCategories(level, code, direction)" @mouseleave="clearScrollInterval()">
            <svg width="22px" heigth="13px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                <g>
                    <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M6.29289 8.79289C6.68342 8.40237 7.31658 8.40237 7.70711 8.79289L12 13.0858L16.2929 8.79289C16.6834 8.40237 17.3166 8.40237 17.7071 8.79289C18.0976 9.18342 18.0976 9.81658 17.7071 10.2071L12.7071 15.2071C12.3166 15.5976 11.6834 15.5976 11.2929 15.2071L6.29289 10.2071C5.90237 9.81658 5.90237 9.18342 6.29289 8.79289Z" />
                </g>
            </svg>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            itemsLength: {
                type: Number,
                default: () => null,
                required: true
            },
            level: {
                type: Number,
                default: () => null,
                required: true
            },
            code: {
                type: String,
                default: () => null,
                required: false
            },
        },
        data() {
            return {
                arrowScrollInterval: null,
            };
        },
        methods: {
            scrollCategories(level, code , direction) {
                const $levelBase = (level === 1) ? $(`.level-${level}-div`) : $(`.level-${level}-div-${code}`);
                const scrollAmount = direction === 'down' ? 30 : -30;

                this.arrowScrollInterval = setInterval(() => {
                    $levelBase.animate({
                        scrollTop: $levelBase.scrollTop() + scrollAmount
                    }, 150);
                }, 300);
            },
            clearScrollInterval() {
                clearInterval(this.arrowScrollInterval);
            },
        },
    };
</script>